<template>
  <div class="min-h-screen">
    <MaterialsShowView :fetch-route="materialShowRoute" @update-material="updateSeo($event)" />
  </div>
</template>
<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'

const materialShowRoute = getAllRoutes().materialsRoutes.materialShowByServer
const { url } = useDomainHost()

const { store } = useDomainState()
function updateSeo(material:Material) {
  useServerSeoMeta(
    useOgMeta(
      url + '/materials/' + material?.id,
      material?.name,
      material?.summary,
      material?.thumb
    )
  )
  useHead({
    title: material?.name,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: material?.summary
      }
    ],
    link: [useSeoCanonical(url + '/materials/' + material?.id)],
    script: [
      useSeoBreadcrumb([
        useSeoBreadcrumbItem(1, store.value?.name, url),
        useSeoBreadcrumbItem(
          2,
          material?.name,
          url + '/materials/' + material?.id
        )
      ]),
      material ? useSeoProduct(material) : {}
    ]
  })
}
</script>
